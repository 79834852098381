import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Container from '../components/Container'
//import ResponsiveVideo from '../components/ResponsiveVideo'
import { PageContext } from '../context/ApplicationState'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
import config from '../utils/siteConfig'

const ProfileHeader = styled.div`
  text-align: center;

  .gatsby-image-wrapper {
    margin-bottom: 20px;
  }

  .headline--large {
    max-width: none;
    margin-bottom: 8px;
  }

  .headline-details {
    font-size: 12px;
    line-height: 16px;

    span {
      display: inline-block;
      position: relative;

      + span {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid currentColor;

        a {
          padding: 4px;
          padding-left: 0;

          + a {
            padding-left: 4px;
          }
        }
      }
    }
  }

  .caption {
    text-align: left;
    margin-top: 10px;
  }

  + .body-content {
    margin-top: 16px;
    margin-bottom: ${props => props.theme.section.bottomSm};

    > div > *:not([class]) {
      padding-left: var(--site-x-padding);
      padding-right: var(--site-x-padding);
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    .gatsby-image-wrapper {
      margin-top: ${props => props.theme.section.topLg};
    }

    .headline--large {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .detail {
      margin-bottom: 60px;
    }

    + .body-content {
      margin-top: calc(${props => props.theme.section.topLg} * 0.75);
      margin-bottom: ${props => props.theme.section.bottomLg};
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.overMedium}) {
    .gatsby-img-container {
      padding-left: var(--site-x-padding);
      padding-right: var(--site-x-padding);
    }

    .caption.wrap {
      padding: 0;
    }
  }
`

const richTextOptions = {
  renderMark: {
    [MARKS.BOLD]: text => <strong>{text}</strong>,
    [MARKS.ITALIC]: text => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { file, fluid, title } = node.data.target
      if (file.contentType.includes('image')) {
        return (
          <>
            <p></p>
            <Img alt={title} fluid={fluid} />
            <p></p>
          </>
        )
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { provider, src } = node.data.target
      /*
      return (
        <>
          <p></p>
          <ul className="video-container">
            <ResponsiveVideo
              videoProvider={provider.toLowerCase()}
              link={src}
            />
          </ul>
          <p></p>
        </>
      )
      */
    },
  },
}

const TopArtistProfileTemplate = ({ data, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)

  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: true,
      hasHeroSection: false,
    }))
  }, [])

  useEffect(() => {
    document.querySelector('body').classList.add('has-light-header')
    return () =>
      document.querySelector('body').classList.remove('has-light-header')
  }, [])

  const {
    name,
    location,
    joinDate,
    heroImage,
    instagramUrl,
    facebookUrl,
    body,
  } = data.contentfulTopArtistProfile

  // const profileNode = data.contentfulTopArtistProfile

  return (
    <>
      <Helmet>
        <title>{`${name} - ${config.siteTitle}`}</title>
      </Helmet>
      {/* <SEO
        pagePath={props.pageContext.pagePath}
        // profileNode={profileNode}
        articleSEO
      /> */}

      <article className="top-artist-profile-container">
        <ProfileHeader>
          <div>
            <Img
              style={{
                maxWidth: '450px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              alt={name}
              fluid={heroImage.fluid}
            />
            <div className="wrap">
              <h1 className="headline--large">{name}</h1>
              <p className="headline-details">
                {location && <span>{location}</span>}
                {joinDate && <span>Artist since {joinDate}</span>}
                {(instagramUrl || facebookUrl) && (
                  <span>
                    {instagramUrl && (
                      <a href={instagramUrl} target="_blank" rel="noopener">
                        <Instagram width="12px" />
                      </a>
                    )}
                    {facebookUrl && (
                      <a href={facebookUrl} target="_blank" rel="noopener">
                        <Facebook width="6px" />
                      </a>
                    )}
                  </span>
                )}
              </p>
            </div>
          </div>
        </ProfileHeader>
        <Container className="body-content has-drop-cap">
          <div>{renderRichText(body, richTextOptions)}</div>
        </Container>
      </article>
    </>
  )
}

export const query = graphql`
  query($slug: String) {
    contentfulTopArtistProfile(slug: { eq: $slug }) {
      name
      slug
      location
      joinDate(formatString: "MMMM, YYYY")
      ...TopArtistProfileImageSettings
      body {
        raw
        references {
          ... on ContentfulPlyrVideo {
            __typename
            contentful_id
            src
            provider
          }
          ... on ContentfulAsset {
            __typename
            contentful_id
            file {
              url
              contentType
            }
            title
            fluid(
              sizes: "(max-width: 767px) calc(100vw - 15px - 10px - 10px - 15px), (max-width: 1023px) calc(50vw - 15px - 12px), (max-width: 1069px) calc(33vw - 15px - 12px), 365px"
              quality: 75
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      instagramUrl
      facebookUrl
    }
  }
`

export default TopArtistProfileTemplate
